import GlobalHelper from '../../src/helpers/global.helper'
import { checkDataLength } from '@nl/lib'
import { libUtils } from '@nl/lib'
import { EnvironmentConfig } from './base.environment'

/**
 * Base class to create new environment configuration
 */
export class Config extends EnvironmentConfig {
    serviceClient = 'ctr/web'
    serviceVersion = 'ctc-dev0'
    baseSiteId = 'GASPLUS'
    banner = 'GASPLUS'
    baseSiteIdAddToCart = 'GASPLUS'
    bannerIdAddToCart = 'GASPLUS'
    language = `${libUtils.getLanguage()}_CA`
    API_BASE_URL = `${window.location.origin}/stubs`
    API_V1 = '/public/dev/v1'
    API_MOCK_URL = `${window.location.origin}/stubs`
    SSO_BASE_URL = 'https://apim-nprd.canadiantire.ca'
    AEM_URL = `http://localhost:8010/proxy/{0}/dynamic`
    JSON_RESPONSE_FORMAT = 'json'
    API_ENDPOINTS: Record<string, string> = {
        contactUs: '/public/dev/v1/contactus/submit',
    }

    /**
     * function to get API configs
     */
    setConfig(): void {
        // init config
        const globalHelper = new GlobalHelper()
        const configs = globalHelper.init()

        if (checkDataLength(configs)) {
            this.API_BASE_URL = configs['apim-domain']
            this.ocpApimSubscriptionKey = configs['apim-subscriptionkey']
            this.baseSiteId = configs['bannerId']
            this.banner = configs['bannerId']
            this.serviceVersion = configs['serviceVersion']
            this.serviceClient = configs['serviceClient']
            this.language = configs['apiLocale'] ?? this.language
            this.getAPIEndpoints(configs)
        }
    }

    /**
     * function to get fallback for API Endpoints
     * @param {Record<string, string>} configs
     * @param {string} configEndpoint
     * @param {string} fallback
     * @return {string} final endpoint
     */
    getEndpointWithFallback(configs: Record<string, string>, configEndpoint: string, fallback: string): string {
        return configs[configEndpoint] || fallback
    }

    /**
     * function to get API Endpoints
     * @param {Record<string, string>} configs
     */
    getAPIEndpoints(configs: Record<string, string>): void {
        this.API_ENDPOINTS.contactUs = this.getEndpointWithFallback(
            configs,
            'apimEndPointContactUs',
            this.API_ENDPOINTS.contactUs,
        )
    }
}

export { Config as LocalConfig }
export default Config
