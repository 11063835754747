import pageLoad from '../components/PageLoad/pageLoad'
import footerCollapseControl from '../components/FooterCollapseControl'
import { Banner } from '@nl/lib'
import accordion from '../components/Accordion'
import { featuredListGridColumnGenerator } from '../components/FeaturedListBrand/featuredListBrand'
import SlickCarouselComponent from '../components/SlickCarouselComponent'

export const staticFunctionMap: {
    [key: string]: {
        method: (element?: Element, params?: Record<string, string>) => void
        param?: Record<string, string>
    }
} = {
    'nl-banner': { method: Banner },
    page: { method: pageLoad },
    'footer-links': { method: footerCollapseControl },
    accordioncontainer: { method: accordion },
    'nl-featured-list': { method: featuredListGridColumnGenerator },
    'carousel-authored': { method: SlickCarouselComponent, param: { slickClass: 'regular' } },
}
