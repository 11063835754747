// External Integrations
import ReactCMSComp from 'react-cms-components/index'

import * as serviceWorker from './serviceWorker'
import { Component } from './global.type'
import handleAppInit from './appInit/index'
import { renderAEMComponents, renderDynamicReactComponent } from './helpers/cms.helper'

const componentArray = ReactCMSComp.return({
    componentList: document.querySelectorAll('[data-component]'),
})

// For rendering AEM components
renderAEMComponents(document)

if (componentArray.length > 0) {
    document.addEventListener('DOMContentLoaded', handleAppInit)
    componentArray.forEach((component: Component) => {
        renderDynamicReactComponent(component)
    })
}

serviceWorker.unregister()
