import { useEffect, useState } from 'react'
import { debounce } from '../utils/debounce'
const REQUEST_DEBOUNCE = 10

type InspirationData = {
    fragmentVariationPath: string
    content: string
}

export const useInspirationData = (fragmentVariationPath: string): [InspirationData | null, boolean] => {
    const [contentDataCache, setContentDataCache] = useState<Record<string, InspirationData>>({})
    const [isFetching, setIsFetching] = useState<boolean>(false)

    useEffect(() => {
        const fetchContentData = debounce(async () => {
            setIsFetching(true)

            try {
                const response = await fetch(`${fragmentVariationPath}.content.html`)
                const data = await response.text()
                const contentData = { fragmentVariationPath, content: data }

                setContentDataCache(prevCache => ({
                    ...prevCache,
                    [fragmentVariationPath]: contentData,
                }))
            } catch (error) {
                console.error(`Error fetching ${fragmentVariationPath} content data:`, error)
            } finally {
                setIsFetching(false)
            }
        }, REQUEST_DEBOUNCE)

        if (!contentDataCache[fragmentVariationPath]) {
            void fetchContentData()
        }

        return () => {
            fetchContentData.cancel()
        }
    }, [fragmentVariationPath, contentDataCache])

    return [contentDataCache[fragmentVariationPath] || null, isFetching]
}
