import { PREFIX, BREAKPOINTS } from '../../config'

/**
 * Init Feature List Brand component
 * @param {Element} element
 */
const initFeaturedListGridColumnGenerator = (element: Element): void => {
    const featureListBrands = element.querySelectorAll(`.${PREFIX}-featured-list__brand-title`)
    const featureListContainer = element.querySelector(`.${PREFIX}-featured-list__display-row--six`)
    const viewMoreFeaturedList = element.querySelector('#view-more')
    const viewLessFeaturedList = element.querySelector('#view-less')
    const { mobileMaxWidth, tabletMaxWidth } = BREAKPOINTS
    const maxCardsPerRowMobile = 4
    const maxCardsPerRowTablet = 8
    const maxCardsPerRowDesktop = 12
    const numOfRows = 2
    const debounceTime = 20
    let timer: number
    let isExpanded = false

    const getCardsToRow = (): number => {
        if (window.innerWidth < mobileMaxWidth) {
            return maxCardsPerRowMobile / numOfRows
        } else if (window.innerWidth < tabletMaxWidth) {
            return maxCardsPerRowTablet / numOfRows
        } else {
            return maxCardsPerRowDesktop / numOfRows
        }
    }

    const getCardsToDisplay = (maxItemsToDisplay: number): number => {
        if (window.innerWidth < mobileMaxWidth) {
            return maxCardsPerRowMobile > maxItemsToDisplay ? maxItemsToDisplay : maxCardsPerRowMobile
        } else if (window.innerWidth < tabletMaxWidth) {
            return maxCardsPerRowTablet > maxItemsToDisplay ? maxItemsToDisplay : maxCardsPerRowTablet
        } else {
            return maxCardsPerRowDesktop > maxItemsToDisplay ? maxItemsToDisplay : maxCardsPerRowDesktop
        }
    }

    const getMaxItemsToDisplay = (): number => {
        const brandItems = viewMoreFeaturedList?.getAttribute('data-brand-item')?.toString()
        return brandItems ? parseInt(brandItems) : featureListBrands?.length
    }

    const showHideCards = (maxItemsToDisplay: number, numItemsToDisplay: number): void => {
        featureListBrands.forEach((item, index) => {
            if (isExpanded && index < maxItemsToDisplay) {
                !item.classList.contains('show') && item.classList.add('show')
            } else if (!isExpanded && index < numItemsToDisplay) {
                !item.classList.contains('show') && item.classList.add('show')
            } else {
                item.classList.contains('show') && item.classList.remove('show')
            }
        })
    }

    const showHideButtons = (showButtons: boolean): void => {
        if (showButtons) {
            if (isExpanded) {
                viewLessFeaturedList?.classList.add('show')
                viewMoreFeaturedList?.classList.remove('show')
            } else {
                viewMoreFeaturedList?.classList.add('show')
                viewLessFeaturedList?.classList.remove('show')
            }
        } else {
            viewMoreFeaturedList?.classList.remove('show')
            viewLessFeaturedList?.classList.remove('show')
        }
    }

    const showCenteredCards = (numItemsToDisplay: number): void => {
        const numRowsForRow = getCardsToRow()

        if (numItemsToDisplay < numRowsForRow) {
            featureListContainer?.setAttribute('style', `--max-number-cols: ${numItemsToDisplay}`)
        } else {
            featureListContainer?.setAttribute('style', '')
        }
    }

    const getTilesToDisplay = (): void => {
        const maxItemsToDisplay = getMaxItemsToDisplay()
        const numItemsToDisplay = getCardsToDisplay(maxItemsToDisplay)
        const showButtons = window.innerWidth < tabletMaxWidth && maxItemsToDisplay > numItemsToDisplay

        showHideCards(maxItemsToDisplay, numItemsToDisplay)
        showHideButtons(showButtons)
        showCenteredCards(numItemsToDisplay)
    }

    const viewMoreAction = (): void => {
        isExpanded = true
        getTilesToDisplay()
    }

    const viewLessAction = (): void => {
        isExpanded = false
        getTilesToDisplay()
    }

    const handleResizeEvent = (): void => {
        clearTimeout(timer)
        timer = setTimeout(function () {
            timer = null
            getTilesToDisplay()
        }, debounceTime)
    }

    viewMoreFeaturedList?.addEventListener('click', viewMoreAction)
    viewLessFeaturedList?.addEventListener('click', viewLessAction)
    window.addEventListener('resize', handleResizeEvent)

    getTilesToDisplay()
}

/**
 * Feature List Brand component
 * @param {string | Element} element
 */
const featuredListGridColumnGenerator = (element: string | Element): void => {
    // Reference to the element where the data prop is added.
    const elementRef = typeof element === 'string' ? document.querySelectorAll(element) : [element]

    elementRef.forEach((el: Element) => {
        initFeaturedListGridColumnGenerator(el)
    })
}

export { featuredListGridColumnGenerator }
